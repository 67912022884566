<template>
    <teleport v-if="mountedComponent" to="#header-nav">
        <CBreadcrumbItem active> Dashboard</CBreadcrumbItem>
    </teleport>

    <div class="icons-preloader">
        <i class="fas fa-location-arrow"></i>
        <i class="fas fa-arrow-right"></i>
    </div>
    <div class="dashboard-filters pb-4">
        <div class="dashboard-filters__select">
            <span class="dashboard-filters__date"
                >{{ days[currentDate.getDay()] }} <strong>{{ today }}</strong></span
            >
            <div class="dashboard-filters__tabs">
                <button
                    v-for="filter in filters"
                    :key="filter.key"
                    class="filter-button"
                    :class="{ 'filter-button_active': selectedFilter === filter.key }"
                    @click="changeFilter(filter.key)">
                    {{ filter.label }}
                </button>
            </div>
            <div style="position: relative">
                <div class="calendar-toggle">
                    <i class="far fa-calendar" @click="toggleCalendar"></i>
                    <span class="dashboard-filters__calendar" @click="toggleCalendar">{{ calendarString }}</span>
                    <i
                        v-if="selectedFilter === 'range'"
                        style="font-size: 13px; color: silver; cursor: pointer"
                        class="fas fa-times"
                        @click="clearRange"></i>
                </div>
                <RangeCalendar
                    v-if="calendar"
                    :selected="{ due_date_from: due_date_from, due_date_to: due_date_to }"
                    @selected="onRangeSelected"
                    @close="calendar = false" />
            </div>
        </div>
        <div class="dashboard-filters__actions">
            <CAction />
        </div>
    </div>

    <div class="dashboard-page">
        <CCard v-if="dashboardData" class="dashboard-page__card">
            <CCardBody class="dashboard-page__card-body">
                <div class="dashboard-page__sections">
                    <template v-if="dashboardData">
                        <section v-for="section in dashboardData" :key="section.slug" class="dashboard-page__section">
                            <div class="dashboard-page__heading">
                                <div style="display: flex; align-items: center">
                                    <CIcon v-if="section.slug === 'todo'" name="cil-user" />
                                    <CIcon v-if="section.slug === 'pipeline'" name="cil-group" />
                                    <CIcon v-if="section.slug === 'all_projects'" name="cil-industry" />
                                    <div style="margin-left: 10px">
                                        {{ section.title }} <i v-if="section.option">({{ section.option }})</i>
                                    </div>
                                </div>

                                <div style="display: flex; align-items: center; gap: 15px">
                                    <CIcon
                                        v-if="section.slug === 'todo'"
                                        name="cilSync"
                                        class="chart-reload-icon"
                                        style="cursor: pointer"
                                        @click="getData" />
                                    <div class="chart-reload">
                                        <img src="@/assets/images/pie.png" alt="" />
                                        <img src="@/assets/images/pie-stat.png" alt="" />
                                    </div>
                                </div>
                            </div>

                            <div class="dashboard-page__charts">
                                <DashboardCard
                                    v-for="chart in section.items"
                                    :key="chart.slug"
                                    :style="{ cursor: chart.total ? 'pointer' : 'unset' }"
                                    :chart-icon="getChartIcon(chart.slug)"
                                    :title="chart.title"
                                    :total="chart.total"
                                    :overdue="chart.overdue"
                                    :background-color="chart.backgroundColor"
                                    :icons="chart.icons"
                                    :chart-data="chart.data"
                                    :additional-data="chart.additionalData"
                                    class="dashboard-page__charts-item"
                                    @click="onChartClick({ section: section.slug, chart: chart })" />
                            </div>
                        </section>
                    </template>
                </div>

                <div v-if="timeline.payload" class="timeline">
                    <TimelineInvitations
                        v-if="timeline.alert"
                        :invitations="invitations"
                        @close="timeline.alert = false" />

                    <div class="timeline__control">
                        <div v-if="!timeline.searching" class="timeline__tabs">
                            <button
                                v-for="tab in timeline.tabs"
                                :key="tab"
                                :data-selected="timeline.selectedTab === tab"
                                @click="toggleTab(tab)">
                                {{ tab }}
                            </button>
                        </div>

                        <div v-if="timeline.searching" class="timeline__search">
                            <div
                                v-if="!timeline.params.type"
                                :class="['timeline__field', { 'timeline__field--active': timeline.searching_focus }]">
                                <CIcon icon="cilSearch" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    @input="onTimelineSearch($event)"
                                    @blur="onFocusAway"
                                    @focus="timeline.searching_focus = true" />
                            </div>

                            <div
                                v-show="timeline.searching_focus && !timeline.params.search"
                                class="timeline__dropdown"
                                @mouseover="timeline.mouse_on_search = true"
                                @mouseleave="timeline.mouse_on_search = false">
                                <span>Quick Links</span>
                                <hr />
                                <div v-if="timeline.selectedTab === 'timeline'">
                                    <p @click="onTimelineParamsChange('type', 'scope')">Scopes</p>
                                    <p @click="onTimelineParamsChange('type', 'contract')">Contracts</p>
                                    <p @click="onTimelineParamsChange('type', 'task')">Tasks</p>
                                    <hr />
                                    <p @click="onTimelineParamsChange('type', 'overdue')">Overdue</p>
                                </div>
                                <div v-if="timeline.selectedTab === 'notifications'">
                                    <p @click="onNotificationsParamsChange('type', 'mentions')">Only my mentions</p>
                                    <p @click="onNotificationsParamsChange('type', 'invitations')">Invitations</p>
                                    <p @click="onNotificationsParamsChange('type', 'overdue')">Overdue items</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="!timeline.searching && !timelineSearchParam" class="timeline__icons">
                            <CIcon name="cilSync" @click="reloadTimeline" />
                            <CIcon name="cilSearch" @click="timeline.searching = true" />
                        </div>

                        <div v-if="timelineSearchParam" class="timeline__clear" @click="removeSearchAndFilters">
                            <span>Clear</span>
                            <img src="@/assets/images/search-clear.svg" alt="" />
                        </div>
                    </div>

                    <div v-if="timeline.selectedTab === 'timeline'">
                        <div v-if="!timelineList && !timeline.loading" class="timeline__empty">
                            <p>
                                {{
                                    timelineSearchParam
                                        ? 'We are sorry. We were unable to dig out some results!'
                                        : 'No events in the timeline yet!'
                                }}
                            </p>
                            <span>{{
                                timelineSearchParam
                                    ? 'Please try to search with another query.'
                                    : 'But we are almost there!'
                            }}</span>
                        </div>

                        <div
                            v-if="timelineList && !timeline.loading"
                            class="timeline__cards"
                            @scroll="onListScroll('events', $event)">
                            <TimelineCard v-for="card in timeline.payload.data" :key="card.id" :card="card" />
                        </div>

                        <div v-if="timeline.loading" class="timeline__skeletons">
                            <TimelineSkeleton v-for="item in 3" :key="`skeleton_${item}`" />
                        </div>
                    </div>

                    <div v-if="timeline.selectedTab === 'notifications'">
                        <div v-if="!timeline.loading && !notificationsList" class="timeline__empty">
                            <p>
                                {{
                                    timelineSearchParam
                                        ? 'We are sorry. We were unable to dig out some results!'
                                        : 'No notifications in the timeline yet!'
                                }}
                            </p>
                            <span>{{
                                timelineSearchParam
                                    ? 'Please try to search with another query.'
                                    : 'But we are almost there!'
                            }}</span>
                        </div>

                        <div
                            v-if="timeline.notifications.payload && !timeline.loading"
                            class="timeline__notifications"
                            @mouseenter="lazyLoadNotifications"
                            @scroll="onListScroll('notifications', $event)">
                            <TimelineNotification
                                v-for="notification in timeline.notifications.payload.data"
                                :key="notification"
                                :notification="notification" />
                        </div>

                        <div v-if="timeline.loading" class="timeline__skeletons">
                            <TimelineSkeleton v-for="item in 3" :key="`skeleton_${item}`" />
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
        <div v-else class="dashboard-page__loading">
            <img src="@/assets/images/charts-load.png" alt="" />
        </div>
    </div>
    <DashboardPanel
        :data="selectedChart"
        :due-date-to="due_date_to"
        :due-date-from="due_date_from"
        :can-select-members="dashboardData && dashboardData.length > 1"
        :params="params"
        @close="selectedChart = false" />
</template>

<script>
import DashboardCard from '@/components/Card/DashboardCard.vue';
import { mapActions } from 'vuex';
import TimelineCard from '@/components/Dashboard/TimelineCard.vue';
import DashboardPanel from '@/components/Dashboard/DashboardPanel.vue';
import RangeCalendar from '@/components/Dashboard/RangeCalendar.vue';
import moment from 'moment';
import TimelineSkeleton from '@/components/Dashboard/TimelineSkeleton.vue';
import TimelineNotification from '@/components/Dashboard/TimelineNotification.vue';
import CAction from '@/components/CAction.vue';
import TimelineInvitations from '@/components/Dashboard/TimelineInvitations.vue';

export default {
    name: 'Index',
    components: {
        TimelineInvitations,
        TimelineNotification,
        CAction,
        TimelineSkeleton,
        RangeCalendar,
        DashboardPanel,
        TimelineCard,
        DashboardCard,
    },
    data() {
        return {
            mountedComponent: false,
            dashboardData: null,
            selectedChart: null,
            search: false,
            searchFocus: false,
            selectedFilter: 'two_weeks',
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            filters: [
                { key: 'today', label: 'Today' },
                { key: 'tomorrow', label: 'Tomorrow' },
                { key: 'one_week', label: '1 Week' },
                { key: 'two_weeks', label: '2 Weeks' },
                { key: 'one_month', label: '1 Month' },
            ],
            date: '',
            calendar: false,
            due_date_from: '',
            due_date_to: '',
            currentDate: new Date(),
            params: {},

            timeline: {
                loading: false,
                lazy_loading: false,
                alert: true,
                searching: false,
                searching_focus: false,
                mouse_on_search: false,
                tabs: ['timeline', 'notifications'],
                selectedTab: 'timeline',

                notifications: {
                    params: {
                        page: 1,
                        per_page: 30,
                    },
                    payload: null,
                },

                params: {
                    page: 1,
                    per_page: 30,
                },

                payload: null,
            },
            invitations: null,
        };
    },
    methods: {
        ...mapActions(['fetchDashboardData']),
        onTimelineSearch(event) {
            const searchText = event.target.value;

            switch (this.timeline.selectedTab) {
                case 'timeline':
                    this.onTimelineParamsChange('search', searchText);
                    break;
                case 'notifications':
                    this.onNotificationsParamsChange('search', searchText);
            }
        },
        onNotificationsParamsChange(prop, value) {
            this.timeline.notifications.params[prop] = value;

            if (prop === 'type') {
                this.timeline.searching = false;
                this.timeline.searching_focus = false;
            }

            this.getNotifications();
        },
        reloadTimeline() {
            if (this.timeline.selectedTab === 'timeline') {
                this.getTimelineData();
                return;
            }

            if (this.timeline.selectedTab === 'notifications') {
                this.getNotifications();
            }
        },
        getNotifications() {
            this.timeline.notifications.params.page = 1;
            this.timeline.loading = true;
            this.$http.dashboard
                .getNotifications(this.timeline.notifications.params)
                .then((res) => (this.timeline.notifications.payload = res.data))
                .finally(() => (this.timeline.loading = false));
        },
        toggleTab(tab) {
            this.timeline.selectedTab = tab;
            const initialParams = {
                page: 1,
                per_page: 30,
            };
            this.timeline.notifications.params = initialParams;
            this.timeline.params = initialParams;

            switch (tab) {
                case 'notifications':
                    this.getNotifications();
                    break;
                case 'timeline':
                    this.getTimelineData();
                    break;
            }
        },
        lazyLoadNotifications() {
            if (
                this.timeline.notifications.payload.total > this.timeline.notifications.payload.data.length &&
                !this.timeline.lazy_loading
            ) {
                this.timeline.lazy_loading = true;
                this.$http.dashboard
                    .getNotifications(this.timeline.params)
                    .then((res) => {
                        const items = [...this.timeline.notifications.payload.data, ...res.data.data];
                        this.timeline.notifications.payload = res.data;
                        this.timeline.notifications.payload.data = items;
                    })
                    .finally(() => (this.timeline.lazy_loading = false));
            }
        },
        lazyLoadEvents() {
            if (this.timeline.payload.total > this.timeline.payload.data.length && !this.timeline.lazy_loading) {
                this.timeline.lazy_loading = true;
                this.$http.dashboard
                    .getTimeline(this.timeline.params)
                    .then((res) => {
                        const items = [...this.timeline.payload.data, ...res.data.data.items.data];
                        this.timeline.payload = res.data.data.items;
                        this.timeline.payload.data = items;
                    })
                    .finally(() => (this.timeline.lazy_loading = false));
            }
        },
        onListScroll(type, event) {
            if (type === 'events') {
                const totalHeight = event.target.scrollHeight;
                const scrolledHeight = event.target.scrollTop;

                if (totalHeight - scrolledHeight < 2000 && !this.timeline.lazy_loading) {
                    this.timeline.params.page++;

                    this.lazyLoadEvents();
                }
            }

            if (type === 'notifications') {
                const totalHeight = event.target.scrollHeight;
                const scrolledHeight = event.target.scrollTop;

                if (totalHeight - scrolledHeight < 2000 && !this.timeline.lazy_loading) {
                    this.timeline.notifications.params.page++;
                    this.lazyLoadNotifications();
                }
            }
        },
        getTimelineData() {
            this.timeline.params.page = 1;
            this.timeline.loading = true;
            this.$http.dashboard
                .getTimeline(this.timeline.params)
                .then((res) => (this.timeline.payload = res.data.data.items))
                .finally(() => (this.timeline.loading = false));
        },
        onTimelineParamsChange(prop, value) {
            this.timeline.params[prop] = value;

            if (prop === 'type') {
                this.timeline.searching = false;
                this.timeline.searching_focus = false;
            }

            this.getTimelineData();
        },
        onFocusAway() {
            if (!this.timeline.mouse_on_search) {
                this.timeline.searching = false;
                this.timeline.searching_focus = false;
            }
        },
        removeSearchAndFilters() {
            if (this.timeline.selectedTab === 'timeline') {
                this.timeline.params.type = '';
                this.timeline.params.search = '';
                this.timeline.searching_focus = false;
                this.timeline.searching = false;

                this.getTimelineData();
                return;
            }

            if (this.timeline.selectedTab === 'notifications') {
                this.timeline.notifications.params.type = '';
                this.timeline.notifications.params.search = '';
                this.timeline.searching_focus = false;
                this.timeline.searching = false;

                this.getNotifications();
            }
        },

        getChartIcon(slug) {
            switch (slug) {
                case 'scope':
                    return 'cilPen';
                case 'task':
                    return 'cilCalendar';
                case 'contract':
                    return 'cilSignature';
            }
        },
        clearRange() {
            this.due_date_to = '';
            this.due_date_from = '';
            this.params = {};
            this.selectedFilter = 'two_weeks';
            this.getData();
        },
        onRangeSelected(data) {
            this.due_date_from = data.due_date_from;
            this.due_date_to = data.due_date_to;
            this.params = {
                due_date_from: moment(this.due_date_from).format('YYYY-MM-DD'),
                due_date_to: moment(this.due_date_to).format('YYYY-MM-DD'),
            };
            this.selectedFilter = 'range';
            this.calendar = false;
            this.getData();
        },
        changeFilter(key) {
            this.selectedFilter = key;
            this.getData();
        },
        onChartClick(payload) {
            if (payload.chart.total) {
                this.selectedChart = payload;
            }
        },
        toggleCalendar() {
            this.calendar = !this.calendar;
        },
        getData() {
            this.dashboardData = null;

            switch (this.selectedFilter) {
                case 'today':
                    this.params = {};
                    this.params.due_date_to = moment(new Date()).format('YYYY-MM-DD');
                    break;
                case 'tomorrow':
                    this.params = {};
                    this.params.due_date_to = moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
                    break;
                case 'one_week':
                    this.params = {};
                    this.params.due_date_to = moment(new Date()).add(6, 'days').format('YYYY-MM-DD');
                    break;
                case 'two_weeks':
                    this.params = {};
                    this.params.due_date_to = moment(new Date()).add(13, 'days').format('YYYY-MM-DD');
                    break;
                case 'one_month':
                    this.params = {};
                    this.params.due_date_to = moment(new Date()).add(29, 'days').format('YYYY-MM-DD');
                    break;
            }

            if (this.selectedFilter !== 'range') {
                this.due_date_from = '';
                this.due_date_to = '';
            }

            this.fetchDashboardData(this.params).then((response) => {
                this.dashboardData = response;
            });
        },
        getInvitations() {
            this.$http.dashboard.getInvitations().then((res) => (this.invitations = res.data.data));
        },
    },
    computed: {
        timelineSearchParam() {
            return (
                this.timeline.params.type ||
                this.timeline.params.search ||
                this.timeline.notifications.params.type ||
                this.timeline.notifications.params.search
            );
        },
        notificationsList() {
            return this.timeline.notifications.payload?.data?.length;
        },
        timelineList() {
            return this.timeline.payload?.data?.length;
        },
        today() {
            return moment(new Date()).format('MM/DD/YY');
        },
        calendarString() {
            const date_from = this.params.due_date_from;
            const date_to = this.params.due_date_to;
            const format = 'MM/DD/YY';

            if (date_from && date_to) {
                return moment(date_from).format(format) + ' - ' + moment(date_to).format(format);
            }

            return 'Custom range';
        },
    },
    mounted() {
        this.getData();
        this.getTimelineData();
        this.getInvitations();
        this.mountedComponent = true;
    },
};
</script>

<style lang="scss">
.dashboard-filters {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .calendar-toggle {
        display: flex;
        align-items: center;
        gap: 5px;

        & i {
            cursor: pointer;
        }
    }

    &__calendar {
        font-size: 12px;
        font-weight: 600;
        margin-left: 4px;
        text-decoration: underline;
        cursor: pointer;
    }

    &__date {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        font-weight: 500;

        & strong {
            font-size: 14px;
            font-weight: 700;
        }
    }

    &__select {
        display: flex;
        align-items: center;
    }

    &__tabs {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: 20px;
        margin-right: 20px;

        & .filter-button {
            background: #c3cdd5;
            height: 28px;
            border-radius: 36px;
            font-weight: 600;
            font-size: 12px;
            color: #fff;
            padding: 0 16px;
            border: none;

            &_active {
                background: #0895d1;
            }
        }
    }
}

.icons-preloader {
    opacity: 0;
    visibility: hidden;
    height: 0;
}

.dashboard-page {
    padding-bottom: 24px;

    & .timeline {
        width: 252px;
        height: 100%;

        &__empty {
            padding: 32px 16px;
            text-align: center;

            & p {
                font-weight: 600;
                font-size: 14px;
                color: #677a89;
                margin-bottom: 0;
            }

            & span {
                font-weight: 400;
                font-size: 12px;
                color: #677a89;
            }
        }

        &__control {
            display: flex;
            justify-content: space-between;
            box-shadow: 0 2px 6px 1px #0000001f;
            border-radius: 4px 4px 0 0;
            padding: 16px 10px;
            border-bottom: 4px solid #dee4e8;
        }

        &__icons {
            display: flex;
            align-items: center;
            gap: 10px;

            & svg {
                cursor: pointer;

                & path {
                    fill: #677a89;
                }
            }
        }

        &__tabs {
            display: flex;
            gap: 5px;

            & button {
                border: none;
                outline: none;
                background: #c3cdd5;
                color: #fff;
                font-weight: 600;
                font-size: 12px;
                border-radius: 36px;
                min-height: 28px;
                padding: 0 10px;
                text-transform: capitalize;
            }

            & [data-selected='true'] {
                background: #0895d1;
            }
        }

        &__cards {
            box-shadow: 0 6px 3px 1px rgba(0, 0, 0, 0.1215686275);
            max-height: 834px;
            overflow: auto;

            .timeline-card {
                &:nth-child(even) {
                    background: #f2f5f8;
                }
            }
        }

        &__notifications {
            box-shadow: 0 6px 3px 1px rgba(0, 0, 0, 0.1215686275);
            max-height: 834px;
            overflow: auto;

            .timeline-notification {
                &:nth-child(even) {
                    background: #f2f5f8;
                }
            }
        }

        &__skeletons {
            padding-top: 20px;
        }

        &__search {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
        }

        &__dropdown {
            position: absolute;
            z-index: 1000;
            background: white;
            box-shadow: 0 7px 16px 2px #0000001c;
            width: 100%;
            border-radius: 4px;
            padding: 12px;
            top: 100%;

            & hr {
                margin: 10px 0;
                color: #677a89;
            }

            & span {
                font-weight: 600;
                font-size: 10px;
                color: #677a89;
                line-height: 1;
            }

            & p {
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                color: #677a89;
                cursor: pointer;

                &:hover {
                    color: black;
                }
            }
        }

        &__field {
            width: 100%;
            border: 1px solid #9fafbc;
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 0 3px;
            transition: 0.3s;

            &--active {
                box-shadow: 0px 0px 0px 4px #9fafbc;
            }

            & svg {
                margin: 0 5px 0 10px;
            }

            & input {
                border: none;
                height: 36px;
                outline: none;
                font-weight: 400;
                color: #1c262f;
                font-size: 14px;
                width: 100%;
            }
        }

        &__clear {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-left: 10px;
            cursor: pointer;

            & span {
                font-weight: 600;
                font-size: 12px;
                color: #677a89;
                text-decoration: underline;
            }
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
    }

    &__card-body {
        padding: 24px 18px 22px 16px;
        display: flex;
    }

    &__sections {
        flex: auto;
        padding-right: 25px;
    }

    &__section {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &_preloader {
            opacity: 0;
            height: 0;
            width: 0;
            position: absolute;
            display: inline-block;
            pointer-events: none;
        }
    }

    &__heading {
        color: #1c262f;
        font-size: 20px;
        font-family: Montserrat;
        font-weight: 600;
        line-height: calc(28 / 20);
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & i {
            font-weight: 400;
            color: #677a89;
            font-size: 16px;
        }

        & .chart-reload {
            border: 2px solid #c0c0c04d;
            border-radius: 5px;

            &-icon {
                & path {
                    fill: black;
                }
            }
        }
    }

    &__charts {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }

    &__card {
        border-radius: 8px;
    }
}
</style>

<template>
  <div class="invitations">

    <div v-if="mode === 'empty'">
      <TimelineInvitation
        @close="$emit('close')"
        :available="false"
        :permission="false"
        title="Companies / Users"
        image="invite"
        :amount="0" />
    </div>

    <div v-if="mode === 'company'">
      <TimelineInvitation
        @close="$emit('close')"
        :available="true"
        :permission="invitations.company_permission || invitations.full_permission"
        type="company"
        :title="invitations.company_invites === 1 ? 'Company' : 'Companies'"
        image="company"
        :amount="invitations.company_invites" />
    </div>

    <div v-if="mode === 'employee'">
      <TimelineInvitation
        @close="$emit('close')"
        :available="true"
        :permission="invitations.full_permission"
        type="user"
        :title="invitations.employee_invites === 1 ? 'User' : 'Users'"
        image="user"
        :amount="invitations.employee_invites" />
    </div>

    <div class="invitations-gallery" v-if="mode === 'mixed'">
      <TimelineInvitation
        @close="$emit('close')"
        :available="true"
        :permission="invitations.company_permission || invitations.full_permission"
        type="company"
        :class="getGalleryClass(1)"
        :title="invitations.company_invites === 1 ? 'Company' : 'Companies'"
        image="company"
        :amount="invitations.company_invites" />
      <TimelineInvitation
        @close="$emit('close')"
        :available="true"
        :permission="invitations.full_permission"
        type="user"
        :class="getGalleryClass(2)"
        :title="invitations.employee_invites === 1 ? 'User' : 'Users'"
        image="user"
        :amount="invitations.employee_invites" />
      <TimelineInvitation
        @close="$emit('close')"
        :available="false"
        :permission="false"
        type="mixed"
        :class="getGalleryClass(3)"
        title="Companies / Users"
        image="invite"
        :amount="invitations.employee_invites + invitations.company_invites" />
    </div>

  </div>
</template>

<script>
import TimelineInvitation from "@/components/Dashboard/TimelineInvitation.vue";

export default{
  name: "TimelineInvitations",
  components: {TimelineInvitation},
  props: ['invitations'],
  data() {
    return {
      counter: 1
    }
  },
  methods: {
    getGalleryClass(item) {

      if (item === this.counter) {
        return 'invitation-active'
      }

    }
  },
  computed: {
    mode() {
      const companyInvites = this.invitations.company_invites;
      const employeeInvites = this.invitations.employee_invites;

      if (companyInvites && employeeInvites) {
        return 'mixed'
      }

      if (companyInvites && !employeeInvites) {
        return 'company'
      }

      if (!companyInvites && employeeInvites) {
        return 'employee'
      }

      return 'empty'
    }
  },
  created() {
    setInterval(() => {

      if (this.counter === 3) {
        this.counter = 1
      } else {
        this.counter++
      }

    }, 5000)
  }
}
</script>

<style lang="scss">
.invitations-gallery {
  position: relative;
  width: 100%;
  min-height: 68px;
  background: white;
  margin-bottom: 16px;

  & .timeline-invitation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: 0.8s;
    z-index: 0;
  }

  & .invitation {

    &-active {
      z-index: 1;
      opacity: 1 !important;
    }
  }
}
</style>
